<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>Integradores</h1>
      </v-flex>
      <v-flex xs6 md3 class="text-center d-none d-md-block"> </v-flex>
      <v-flex xs12 md3 class="text-center d-none d-md-block">
        <h3>Nº Integradores</h3>
        <v-chip>{{ operator.integrators.length }}</v-chip>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-card elevation="1" class="mt-3">
          <v-simple-table dense>
            <template v-slot:top>
              <v-toolbar flat>
                <div class="flex-grow-1"></div>
                <v-btn
                  class="mr-2"
                  color="primary"
                  elevation="2"
                  x-small
                  v-show="visibleAdd"
                  @click="add"
                >
                  <v-icon small>mdi-plus</v-icon>Añadir
                </v-btn>
              </v-toolbar>
            </template>

            <template>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Fecha inicio</th>
                  <th>Fecha fin</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in operator.integrators"
                  :key="item.integratorId"
                  v-on:click="onIntegratorSelected(item)"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.start }}</td>
                  <td>{{ item.end }}</td>
                  <td class="text-right">
                    <v-btn outlined color="primary" elevation="2" x-small @click="edit(item)">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>

      <v-flex xs12 md6>
        <IntegratorMediaAccess
          :operatorIntegrator="operatorIntegratorSelected"
        ></IntegratorMediaAccess>
      </v-flex>
    </v-layout>

    <v-dialog v-model="editorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Integrador
        </v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="integratorItem.integratorId"
                      :items="avaliableClients"
                      item-value="id"
                      item-text="name"
                      filled
                      hide-details="auto"
                      label="Seleccionar integrador"
                      :readonly="editMode"
                      :rules="[v => !!v || 'El Integrador es obligatorio.']"
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      v-model="start"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="integratorItem.start"
                          label="Fecha Inicio"
                          filled
                          clearable
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateStart"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      v-model="end"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="integratorItem.end"
                          label="Fecha fin"
                          filled
                          clearable
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateEnd"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>

        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import IntegratorMediaAccess from "../components/IntegratorMediaAccess.vue";
const validation = require("../shared/validation");
export default {
  name: "Integrators",
  components: { IntegratorMediaAccess },
  data: () => ({
    requiredRule: [v => (v != null && v != "") || "Campo obligatorio"],
    editorDialog: false,
    editMode: false,
    start: false,
    end: false,
    dateStart: null,
    dateEnd: null,
    avaliableClients: {},
    integratorItem: {},
    operatorIntegratorSelected: null,
  }),
  created() {
    this.$store.dispatch("fetchClients");
  },
  watch: {
    dateStart() {
      this.integratorItem.start = validation.formatDate(this.dateStart);
    },
    dateEnd() {
      this.integratorItem.end = validation.formatDate(this.dateEnd);
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    operator() {
      return this.$store.getters.getOperator;
    },
    clients() {
      return this.$store.getters.getClients;
    },
    visibleAdd() {
      return this.clients?.length != this.operator.integrators.length;
    },
  },
  methods: {
    add: function() {
      this.avaliableClients = [];
      this.$store.dispatch("getAvaliableClients").then(response => {
        this.avaliableClients = response;
        this.integratorItem = {};
        this.editorDialog = true;
        this.editMode = false;
      });
    },
    edit: function(item) {
      this.avaliableClients = this.clients;
      this.integratorItem = item;
      this.editorDialog = true;
      this.editMode = true;
      if (item.start != null) {
        this.dateStart = validation.parseDate(item.start);
      }
      if (item.end != null) {
        this.dateEnd = validation.parseDate(item.end);
      }
    },
    onIntegratorSelected: function(item) {
      this.operatorIntegratorSelected = {
        integratorId: item.integratorId,
        operatorId: this.operator.operatorId,
        integrator: item.name,
      };
    },
    save: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.$store.dispatch("saveOperatorIntegrator", this.integratorItem).then(
          () => {
            if (!this.editMode) this.$store.dispatch("fetchOperator");
            this.editorDialog = false;
          },
          () => {
            this.editorDialog = true;
          }
        );
      }
    },
  },
};
</script>

<style scoped></style>
